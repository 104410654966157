<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_user") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateUser"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import languageList from "../i18n/list";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const roles = computed(() => store.state.role.roles);
    const user = computed(() => store.getters["user/getUser"]);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const filteredRoles = computed(() =>
      roles.value.filter((role) => {
        if (route.params.companyId && role.label.includes("manager"))
          return role;
        if (route.params.partnerId && role.label.includes("partner"))
          return role;
      })
    );

    const formElements = ref(null);

    const setFormElements = () => {
      if (route.params.companyId || route.params.partnerId) {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "first-name",
            label: "first_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.firstName,
            variable: "firstName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "last-name",
            label: "last_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.lastName,
            variable: "lastName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "role-select",
            label: "role",
            optionLabel: ["label"],
            options: filteredRoles.value,
            rules: ["isRequired"],
            translatableLabel: "roles",
            value: user.value.roleId,
            variable: "roleId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: "white",
            type: "submit",
            variant: "indigo",
          },
        ];
      } else {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "first-name",
            label: "first_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.firstName,
            variable: "firstName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "last-name",
            label: "last_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.lastName,
            variable: "lastName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "language",
            label: "language",
            optionLabel: ["name"],
            options: languageList,
            rules: ["isRequired"],
            value: user.value.language,
            variable: "language",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "phone",
            label: "phone",
            rules: ["isRequired"],
            type: "text",
            value: user.value.phone,
            variable: "phone",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: "white",
            type: "submit",
            variant: "indigo",
          },
        ];
      }
    };

    onMounted(() => {
      if (
        (route.params.companyId || route.params.partnerId) &&
        !roles.value.length
      ) {
        store.dispatch("role/getRoles");
      }
      store.dispatch("user/getUser", route.params.userId);
      if (
        route.params.companyId &&
        (!company.value.id || company.value.id != route.params.companyId)
      ) {
        store.dispatch("company/getCompany", route.params.companyId);
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      company,
      formElements,
      t,
      updateUser: (data) => {
        data.id = user.value.id;
        store.dispatch("user/updateUser", data);
      },
      waiting,
    };
  },
};
</script>
